import gql from "graphql-tag"
import * as Urql from "urql"
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: string
	String: string
	Boolean: boolean
	Int: number
	Float: number
}

export type AuthResponse = {
	__typename?: "AuthResponse"
	errors?: Maybe<Array<FieldError>>
	redirectUrl?: Maybe<Scalars["String"]>
	sessionId?: Maybe<Scalars["String"]>
}

export type FieldError = {
	__typename?: "FieldError"
	error: Scalars["String"]
	field: Scalars["String"]
}

export type Mutation = {
	__typename?: "Mutation"
	loginAsSchool: AuthResponse
	loginAsStaff: AuthResponse
	loginAsStudent: AuthResponse
}

export type MutationLoginAsSchoolArgs = {
	password: Scalars["String"]
	username: Scalars["String"]
}

export type MutationLoginAsStaffArgs = {
	password: Scalars["String"]
	username: Scalars["String"]
}

export type MutationLoginAsStudentArgs = {
	password: Scalars["String"]
	username: Scalars["String"]
}

export type Query = {
	__typename?: "Query"
	hello: Scalars["String"]
}

export type LoginAsSchoolMutationVariables = Exact<{
	username: Scalars["String"]
	password: Scalars["String"]
}>

export type LoginAsSchoolMutation = {
	__typename?: "Mutation"
	loginAsSchool: {
		__typename?: "AuthResponse"
		sessionId?: string | null
		redirectUrl?: string | null
		errors?: Array<{ __typename?: "FieldError"; field: string; error: string }> | null
	}
}

export type LoginAsStaffMutationVariables = Exact<{
	username: Scalars["String"]
	password: Scalars["String"]
}>

export type LoginAsStaffMutation = {
	__typename?: "Mutation"
	loginAsStaff: {
		__typename?: "AuthResponse"
		sessionId?: string | null
		redirectUrl?: string | null
		errors?: Array<{ __typename?: "FieldError"; field: string; error: string }> | null
	}
}

export type LoginAsStudentMutationVariables = Exact<{
	username: Scalars["String"]
	password: Scalars["String"]
}>

export type LoginAsStudentMutation = {
	__typename?: "Mutation"
	loginAsStudent: {
		__typename?: "AuthResponse"
		sessionId?: string | null
		redirectUrl?: string | null
		errors?: Array<{ __typename?: "FieldError"; field: string; error: string }> | null
	}
}

export const LoginAsSchoolDocument = gql`
	mutation LoginAsSchool($username: String!, $password: String!) {
		loginAsSchool(username: $username, password: $password) {
			errors {
				field
				error
			}
			sessionId
			redirectUrl
		}
	}
`

export function useLoginAsSchoolMutation() {
	return Urql.useMutation<LoginAsSchoolMutation, LoginAsSchoolMutationVariables>(LoginAsSchoolDocument)
}
export const LoginAsStaffDocument = gql`
	mutation LoginAsStaff($username: String!, $password: String!) {
		loginAsStaff(username: $username, password: $password) {
			errors {
				field
				error
			}
			sessionId
			redirectUrl
		}
	}
`

export function useLoginAsStaffMutation() {
	return Urql.useMutation<LoginAsStaffMutation, LoginAsStaffMutationVariables>(LoginAsStaffDocument)
}
export const LoginAsStudentDocument = gql`
	mutation LoginAsStudent($username: String!, $password: String!) {
		loginAsStudent(username: $username, password: $password) {
			errors {
				field
				error
			}
			sessionId
			redirectUrl
		}
	}
`

export function useLoginAsStudentMutation() {
	return Urql.useMutation<LoginAsStudentMutation, LoginAsStudentMutationVariables>(LoginAsStudentDocument)
}
