import { Flex, Heading, HStack, Icon, ScaleFade, Text, VStack } from "@chakra-ui/react"
import React, { useState } from "react"
import { Check } from "react-feather"

const modes = ["School", "Staff", "Student"]

export type ModeSelectorProps = {
	onChange: (index: 0 | 1 | 2) => void
}

export const ModeSelector: React.FC<ModeSelectorProps> = ({ onChange }) => {
	const [selectedMode, setSelectedMode] = useState<0 | 1 | 2>(0)
	return (
		<VStack w="full" align="flex-start">
			<Heading fontSize="md" fontWeight="semibold" lineHeight="100%" color="#515151">
				Log in as
			</Heading>
			<Flex flexWrap="wrap" w="full">
				{modes.map((mode, index) => (
					<HStack
						key={index}
						m="0.5"
						bg="whiteAlpha.700"
						border="2px solid"
						borderColor="purple.500"
						opacity={selectedMode === index ? 1 : 0.5}
						rounded="xl"
						px="4"
						py="2"
						cursor="pointer"
						_hover={{ transform: "scale(1.04)", opacity: selectedMode === index ? 1 : 0.8 }}
						transition="transform 100ms ease-in-out"
						userSelect="none"
						onClick={() => {
							setSelectedMode(index as 0 | 1 | 2)
							onChange(index as 0 | 1 | 2)
						}}
					>
						<ScaleFade in={selectedMode === index} unmountOnExit>
							<Icon fontSize="xl" color="purple.500" as={Check} />
						</ScaleFade>
						<Text fontSize="sm" fontWeight={selectedMode === index ? "semibold" : "normal"} color="purple.500">
							{mode}
						</Text>
					</HStack>
				))}
			</Flex>
		</VStack>
	)
}
