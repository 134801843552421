import React from "react"
import { LandingPage } from "./pages"

export const App: React.FC = () => {
	return (
		<div id="app">
			<LandingPage />
		</div>
	)
}
