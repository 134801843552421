import {
	AspectRatio,
	Button,
	FormControl,
	FormErrorIcon,
	FormErrorMessage,
	FormLabel,
	Heading,
	Icon,
	Img,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	ModalProps,
	SlideFade,
	Text,
	VStack,
} from "@chakra-ui/react"
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useFormik } from "formik"
import React from "react"

export type ContestModalProps = {
	contest: { _id: string; title: string; description: string; picture: string }
} & Omit<ModalProps, "children">

export const ContestModal: React.FC<ContestModalProps> = ({ contest, ...props }) => {
	const formik = useFormik<{ email: string }>({
		initialValues: { email: "" },
		onSubmit: console.log,
	})

	return (
		<Modal size="lg" {...props}>
			<ModalOverlay backdropFilter="blur(4px)">
				<ModalContent rounded="2xl">
					<ModalCloseButton _focus={{ shadow: "none" }} zIndex={999} />
					<ModalBody p="0">
						<VStack w="full" align="flex-start" spacing="4">
							<VStack bg="white.500" rounded="2xl" spacing={4} shadow="sm" maxW="lg">
								<AspectRatio pos="relative" w="full" ratio={16 / 9} cursor="pointer">
									<Img roundedTop="xl" src={contest.picture} />
								</AspectRatio>

								<VStack w="full" align="stretch" p={{ base: 2, md: 4 }} spacing={4}>
									<Heading as="h1" fontSize="xl" color="text.500">
										{contest?.title}
									</Heading>
									<VStack as="form" w="full" align="stretch">
										<FormControl>
											<SlideFade in={Boolean(formik.values.email)} unmountOnExit>
												<FormLabel htmlFor="email" fontSize="sm">
													Email
												</FormLabel>
											</SlideFade>
											<Input
												placeholder="Enter your email"
												maxW="lg"
												bg="white.500"
												rounded="xl"
												py="1"
												autoComplete="off"
												{...formik.getFieldProps("email")}
											/>
											<FormErrorMessage>
												<FormErrorIcon />
												<Text>{formik.errors.email}</Text>
											</FormErrorMessage>
										</FormControl>

										<Button
											colorScheme="primary"
											size="sm"
											leftIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faPaperPlane} {...props} />} />}
										>
											Apply
										</Button>
									</VStack>
									<Text fontSize="sm" color="text.400" whiteSpace="pre-line">
										{contest.description}
									</Text>
								</VStack>
							</VStack>
						</VStack>
					</ModalBody>
				</ModalContent>
			</ModalOverlay>
		</Modal>
	)
}
