import {
	Button,
	FormControl,
	FormErrorIcon,
	FormErrorMessage,
	FormHelperText,
	FormLabel,
	Icon,
	Input,
	SlideFade,
	Text,
	useToast,
	VStack,
} from "@chakra-ui/react"
import { FormikHelpers, useFormik } from "formik"
import React from "react"
import { ArrowRight } from "react-feather"
import * as Yup from "yup"
import { LoginAsStudentMutationVariables, useLoginAsStudentMutation } from "../../graphql"

const validationSchema = Yup.object({
	username: Yup.string().required().label("Username"),
	password: Yup.string().required().label("Password"),
})

export const LoginAsStudentForm: React.FC = () => {
	const [{ fetching }, login] = useLoginAsStudentMutation()

	const toast = useToast()

	const handleSubmit = async ({ username, password }: LoginAsStudentMutationVariables, helpers: FormikHelpers<LoginAsStudentMutationVariables>) => {
		const { error, data } = await login({ username, password })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL ]", ""),
				status: "error",
			})
		}

		if (data?.loginAsStudent.errors) {
			return data?.loginAsStudent.errors.forEach(({ field, error }) => {
				return helpers.setFieldError(field, error)
			})
		}

		if (data?.loginAsStudent.sessionId && data?.loginAsStudent.redirectUrl) {
			return (window.location.href = data?.loginAsStudent.redirectUrl + `?sessionId=${data.loginAsStudent.sessionId}`)
		}
	}

	const formik = useFormik<LoginAsStudentMutationVariables>({
		initialValues: { username: "", password: "" },
		validationSchema,
		onSubmit: handleSubmit,
	})

	return (
		<form onSubmit={formik.handleSubmit}>
			<VStack align="flex-start" spacing="4">
				<FormControl isInvalid={Boolean(formik.errors.username && formik.touched.username)}>
					<SlideFade in={Boolean(formik.values.username)} unmountOnExit>
						<FormLabel htmlFor="username" fontSize="sm">
							Username
						</FormLabel>
					</SlideFade>
					<Input
						placeholder="Username"
						border="none"
						_focus={{ border: "none" }}
						maxW="sm"
						bg="white.500"
						rounded="xl"
						py="1"
						autoComplete="off"
						{...formik.getFieldProps("username")}
					/>
					<FormErrorMessage>
						<FormErrorIcon />
						<Text>{formik.errors.username}</Text>
					</FormErrorMessage>{" "}
					<FormHelperText>Example: student@school</FormHelperText>
				</FormControl>
				<FormControl isInvalid={Boolean(formik.errors.password && formik.touched.password)}>
					<SlideFade in={Boolean(formik.values.password)} unmountOnExit>
						<FormLabel htmlFor="password" fontSize="sm">
							Password
						</FormLabel>
					</SlideFade>
					<Input
						type="password"
						placeholder="Password"
						border="none"
						_focus={{ border: "none" }}
						maxW="sm"
						bg="white.500"
						rounded="xl"
						py="1"
						{...formik.getFieldProps("password")}
					/>
					<FormErrorMessage>
						<FormErrorIcon />
						<Text>{formik.errors.password}</Text>
					</FormErrorMessage>
				</FormControl>
				<Button type="submit" colorScheme="primary" size="md" rightIcon={<Icon as={ArrowRight} />} isLoading={fetching}>
					Sign in
				</Button>
			</VStack>
		</form>
	)
}
