import { AspectRatio, Button, Center, Heading, Icon, Img, SlideFade, Text, useBreakpointValue, useDisclosure, VStack } from "@chakra-ui/react"
import { faPlay } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { ArrowRight } from "react-feather"
import { Card } from "../common"
import { WorkshopModal } from "./WorkshopModal"

export type WorkshopThumbnailProps = {
	workshop: {
		_id: string
		title: string
		description: string
		picture: string
	}
	size?: "xs" | "sm" | "md" | "lg" | "full"
}

export const WorkshopThumbnail: React.FC<WorkshopThumbnailProps> = ({ workshop, size = "sm" }) => {
	const [isActive, setIsActive] = useState(false)

	const variants = {
		xs: {
			maxW: 48,
			fontSize: {
				title: "0.6rem",
				subtitle: "6px",
			},
			p: 2,
		},
		sm: {
			maxW: 56,
			fontSize: {
				title: "xs",
				subtitle: "10px",
			},
			p: 2,
		},
		md: {
			maxW: 64,
			fontSize: {
				title: "sm",
				subtitle: "12px",
			},
			p: 3,
		},
		lg: {
			maxW: 80,
			fontSize: {
				title: "md",
				subtitle: "14px",
			},
			p: 4,
		},
		full: {
			maxW: "full",
			fontSize: {
				title: "xl",
				subtitle: "16px",
			},
			p: 4,
		},
	}

	const isMobile = useBreakpointValue({ base: true, md: false })

	const { isOpen, onClose, getButtonProps } = useDisclosure()

	return (
		<>
			<Card
				w="full"
				p="0"
				bg="whitesmoke"
				onMouseEnter={() => {
					!isMobile && setIsActive(true)
				}}
				onMouseLeave={() => {
					!isMobile && setIsActive(false)
				}}
				{...getButtonProps()}
			>
				<AspectRatio pos="relative" minW={variants[size].maxW} maxW="full" ratio={16 / 9} cursor="pointer">
					<>
						<Img roundedTop="2xl" src={workshop.picture} />

						<Center pos="absolute" top="0" bottom="0" left="0" right="0">
							<Center bg={isActive ? "primary.500" : "blackAlpha.600"} transition="background-color 0.1s ease-in-out" px="6" py="3" rounded="xl">
								<Icon as={(props: any) => <FontAwesomeIcon icon={faPlay} {...props} />} color="whitesmoke" fontSize="2xl" />
							</Center>
						</Center>
					</>
				</AspectRatio>
				<VStack w="full" align="flex-start" p="2">
					<Heading fontSize={variants[size].fontSize.title} color="text.500">
						{workshop.title}
					</Heading>
					<SlideFade in={isActive || isMobile} unmountOnExit>
						<VStack w="full" align="stretch">
							<Text
								fontSize={variants[size].fontSize.subtitle}
								display="-webkit-box"
								style={{
									WebkitLineClamp: 3,
									WebkitBoxOrient: "vertical",
									overflow: "hidden",
								}}
							>
								{workshop.description}
							</Text>
						</VStack>
					</SlideFade>
					<Button colorScheme="primary" size="xs" variant="link" rightIcon={<Icon as={ArrowRight} />}>
						See details
					</Button>
				</VStack>
			</Card>
			<WorkshopModal isOpen={isOpen} onClose={onClose} workshop={workshop} />
		</>
	)
}
