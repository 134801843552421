import { AspectRatio, Button, Heading, Icon, Img, SlideFade, Text, useBreakpointValue, useDisclosure, VStack } from "@chakra-ui/react"
import React, { useState } from "react"
import { ArrowRight } from "react-feather"
import { Card } from "../common"
import { InternshipModal } from "./InternshipModal"

export type InternshipThumbnailProps = {
	internship: {
		_id: string
		title: string
		description: string
		picture: string
	}
	size?: "xs" | "sm" | "md" | "lg" | "full"
}

export const InternshipThumbnail: React.FC<InternshipThumbnailProps> = ({ internship, size = "sm" }) => {
	const [isActive, setIsActive] = useState(false)

	const variants = {
		xs: {
			maxW: 48,
			fontSize: {
				title: "0.6rem",
				subtitle: "6px",
			},
			p: 2,
		},
		sm: {
			maxW: 56,
			fontSize: {
				title: "xs",
				subtitle: "10px",
			},
			p: 2,
		},
		md: {
			maxW: 64,
			fontSize: {
				title: "sm",
				subtitle: "12px",
			},
			p: 3,
		},
		lg: {
			maxW: 80,
			fontSize: {
				title: "md",
				subtitle: "14px",
			},
			p: 4,
		},
		full: {
			maxW: "full",
			fontSize: {
				title: "xl",
				subtitle: "16px",
			},
			p: 4,
		},
	}

	const isMobile = useBreakpointValue({ base: true, md: false })

	const { isOpen, onClose, getButtonProps } = useDisclosure()

	return (
		<>
			<Card
				w="full"
				p="0"
				bg="whitesmoke"
				onMouseEnter={() => {
					!isMobile && setIsActive(true)
				}}
				onMouseLeave={() => {
					!isMobile && setIsActive(false)
				}}
				{...getButtonProps()}
			>
				<AspectRatio pos="relative" minW={variants[size].maxW} maxW="full" ratio={16 / 9} cursor="pointer">
					<Img roundedTop="2xl" src={internship.picture} />
				</AspectRatio>
				<VStack w="full" align="flex-start" p="2">
					<Heading fontSize={variants[size].fontSize.title} color="text.500">
						{internship.title}
					</Heading>
					<SlideFade in={isActive || isMobile} unmountOnExit>
						<VStack w="full" align="stretch">
							<Text
								fontSize={variants[size].fontSize.subtitle}
								display="-webkit-box"
								style={{
									WebkitLineClamp: 3,
									WebkitBoxOrient: "vertical",
									overflow: "hidden",
								}}
							>
								{internship.description}
							</Text>
						</VStack>
					</SlideFade>
					<Button colorScheme="primary" size="xs" variant="link" rightIcon={<Icon as={ArrowRight} />}>
						Apply
					</Button>
				</VStack>
			</Card>
			<InternshipModal isOpen={isOpen} onClose={onClose} internship={internship} />
		</>
	)
}
